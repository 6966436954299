import { MbscModule } from "@mobiscroll/angular-lite";
import { HttpClientModule } from "@angular/common/http";
import { ScreenOrientation } from "@awesome-cordova-plugins/screen-orientation/ngx";
import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { InAppBrowser } from "@ionic-native/in-app-browser/ngx";
import { IonicModule } from "@ionic/angular";
import { IonicStorageModule } from "@ionic/storage";
import { SocialSharing } from "@ionic-native/social-sharing/ngx";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { ServiceWorkerModule } from "@angular/service-worker";
import { environment } from "../environments/environment";
import { FormsModule } from "@angular/forms";
import { File } from "@awesome-cordova-plugins/file/ngx";
import { CoreModule } from "./core/core.module";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MAT_DATE_LOCALE } from "@angular/material/core";

@NgModule({
  imports: [
    MbscModule,
    BrowserModule,
    AppRoutingModule,
    CoreModule,
    HttpClientModule,
    FormsModule,
    BrowserAnimationsModule,
    IonicModule.forRoot(),
    IonicStorageModule.forRoot(),
    ServiceWorkerModule.register("ngsw-worker.js", {
      enabled: environment.production,
    }),
  ],
  declarations: [AppComponent],
  providers: [
    InAppBrowser,
    SocialSharing,
    File,
    ScreenOrientation,
    { provide: MAT_DATE_LOCALE, useValue: "es-ES" },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
