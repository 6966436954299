<ion-app [class.dark-theme]="dark">
  <ion-split-pane contentId="main-content">
    <ion-menu contentId="main-content" style="width: 300px">
      <ion-header style="border-bottom: 2px solid var(--ion-color-primary)">
        <ion-toolbar color="primary">
          <ion-title
            style="
              color: white;
              padding-left: 0;
              padding-right: 0;
              margin-top: 2.5px;
              margin-bottom: 2.5px;
            "
            (click)="navigateToRoot('menu')"
          >
            <img
              style="height: 50px"
              src="../assets/img/logos/logo_i4life_es_blanco.svg"
            />
          </ion-title>
        </ion-toolbar>
      </ion-header>

      <ion-content style="--background: none">
        <ion-list lines="none">
          <ion-menu-toggle autoHide="false">
            <ion-item
              button
              (click)="navigateToRoot('menu')"
              style="margin-bottom: 3%"
            >
              <ion-icon
                slot="start"
                src="../assets/img/icons/apps-outline.svg"
              ></ion-icon>
              <ion-label> Menú </ion-label>
            </ion-item>
            <ion-item
              button
              (click)="navigateToRoot('atencion-usuarios')"
              style="margin-bottom: 2%"
            >
              <ion-icon
                slot="start"
                src="../assets/img/icons/id-card-outline.svg"
              ></ion-icon>
              <ion-label> Atención de usuari@s </ion-label>
            </ion-item>

            <ion-item
              button
              (click)="navigateToRoot('gestion-usuarios')"
              style="margin-bottom: 2%"
            >
              <ion-icon
                slot="start"
                src="../assets/img/icons/person-outline.svg"
              ></ion-icon>
              <ion-label> Usuari@s </ion-label>
            </ion-item>

            <ion-item
              button
              (click)="navigateToRoot('gestion-empleados')"
              style="margin-bottom: 2%"
            >
              <ion-icon
                slot="start"
                src="../assets/img/icons/medkit-outline.svg"
              ></ion-icon>
              <ion-label> Acompañantes silver</ion-label>
            </ion-item>

            <ion-item
              button
              (click)="navigateToRoot('gestion-familiares')"
              style="margin-bottom: 2%"
            >
              <ion-icon
                slot="start"
                src="../assets/img/icons/people-outline.svg"
              ></ion-icon>
              <ion-label> Familiares </ion-label>
            </ion-item>

            <ion-item
              button
              (click)="navigateToRoot('gestion-anunciantes')"
              style="margin-bottom: 2%"
            >
              <ion-icon
                slot="start"
                src="../assets/img/icons/megaphone-outline.svg"
              ></ion-icon>
              <ion-label> Anunciantes </ion-label>
            </ion-item>

            <ion-item
              button
              (click)="navigateToRoot('gestion-viviendas')"
              style="margin-bottom: 2%"
            >
              <ion-icon
                slot="start"
                src="../assets/img/icons/home-outline.svg"
              ></ion-icon>
              <ion-label> Viviendas </ion-label>
            </ion-item>

            <ion-item
              button
              (click)="navigateToRoot('gestion-dispositivos')"
              style="margin-bottom: 2%"
            >
              <ion-icon
                slot="start"
                src="../assets/img/icons/watch-outline.svg"
              ></ion-icon>
              <ion-label> Dispositivos </ion-label>
            </ion-item>
          </ion-menu-toggle>
        </ion-list>
        <ion-list lines="none">
          <ion-menu-toggle autoHide="false">
            <ion-item
              button
              detail="false"
              (click)="logout()"
              color="dangerSoft"
            >
              <ion-icon
                slot="start"
                src="../assets/img/icons/log-out-outline.svg"
                style="color: white"
              ></ion-icon>
              <ion-label>Cerrar sesión</ion-label>
            </ion-item>
          </ion-menu-toggle>
        </ion-list>
        <div class="logos-zamora" style="display: flex">
          <img
            src="assets/img/logos/Diputacion Zamora Logo 2020 2H color.png"
            alt="Diputación de Zamora"
          />

          <img
            style="width: 25%"
            src="assets/img/logos/logo territory silver economy.jpg"
            alt="Silver economy"
          />
        </div>
        <div style="text-align: center">
          <img
            style="width: 90%"
            src="assets/img/logos/IMAGEN-Logotipo_de_la_Secretaría_General_para_el_Reto_Demográfico.png"
            alt=""
          />
        </div>
        <ion-footer>
          <a
            href="https://i4life.es/silverdigi-politica_privacidad/"
            target="_blank"
          >
            Política de Privacidad
          </a>
          <br />
          <a href="https://i4life.es/silverdigi-contrato_uso/" target="_blank">
            Licencia de uso </a
          ><br />
          <a href="https://i4life.es/silverdigi-aviso_legal/" target="_blank">
            Aviso legal</a
          ></ion-footer
        >

        <!-- <div style="position: relative; height: 18%; width: 35%; margin: auto">
          <img
            style="height: 100%"
            src="{{ serverroute }}/uploads/images/councils/{{ idCouncil }}.png"
            alt=""
          />
        </div> -->
      </ion-content>
    </ion-menu>

    <ion-router-outlet id="main-content"></ion-router-outlet>
  </ion-split-pane>
</ion-app>
