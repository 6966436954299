import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { CheckTutorial } from "./providers/check-tutorial.service";

const routes: Routes = [
  {
    path: "",
    redirectTo: "/login",
    pathMatch: "full",
  },
  {
    path: "login",
    loadChildren: () =>
      import("./pages/login/login.module").then((m) => m.LoginModule),
  },
  {
    path: "menu",
    loadChildren: () =>
      import("./pages/menu/menu.module").then((m) => m.MenuPageModule),
  },
  {
    path: "atencion-usuarios",
    loadChildren: () =>
      import("./pages/control-enfermeria/control-enfermeria.module").then(
        (m) => m.ControlEnfermeriaPageModule
      ),
  },
  {
    path: "control-constantes",
    loadChildren: () =>
      import("./pages/control-constantes/control-constantes.module").then(
        (m) => m.ControlConstantesPageModule
      ),
  },
  {
    path: "gestion-usuarios",
    loadChildren: () =>
      import("./pages/gestion-usuarios/gestion-usuarios.module").then(
        (m) => m.GestionPacientesPageModule
      ),
  },
  {
    path: "gestion-empleados",
    loadChildren: () =>
      import("./pages/gestion-empleados/gestion-empleados.module").then(
        (m) => m.GestionEmpleadosPageModule
      ),
  },
  {
    path: "gestion-viviendas",
    loadChildren: () =>
      import("./pages/gestion-viviendas/gestion-viviendas.module").then(
        (m) => m.GestionViviendasModule
      ),
  },

  {
    path: "gestion-familiares",
    loadChildren: () =>
      import("./pages/familiares/familiares.module").then(
        (m) => m.FamiliaresPageModule
      ),
  },
  {
    path: "gestion-anunciantes",
    loadChildren: () =>
      import("./pages/advertiser/advertiser.module").then(
        (m) => m.AdvertiserPageModule
      ),
  },
  {
    path: "formulario-support",
    loadChildren: () =>
      import("./pages/formulario-support/formulario-support.module").then(
        (m) => m.FormularioSupportPageModule
      ),
  },
  {
    path: "alertas",
    loadChildren: () =>
      import("./pages/alertas/alertas.module").then((m) => m.AlertasPageModule),
  },  {
    path: 'gestion-dispositivos',
    loadChildren: () => import('./pages/gestion-dispositivos/gestion-dispositivos.module').then( m => m.GestionDispositivosPageModule)
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
